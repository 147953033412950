import React, { useState } from 'react'

const defaultState = {
    packageOption: ''
}

const BookingContext = React.createContext(defaultState)

const BookingProvider = ({children}) => {
    const [packageOption, setPackageOption] = useState('')

    return (
        <BookingContext.Provider value={{packageOption, setPackageOption}}>
            {children}
        </BookingContext.Provider>
    )
}

export default BookingContext
export { BookingProvider }
